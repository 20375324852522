<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap justify-content-start">
        <div class="d-flex align-items-center">
          <TMessage content="Compensation" bold />
          <TSpinner :loading="creating" />
        </div>
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            color="primary"
            class="mr-1"
            @click="clearInput"
          />
          <TButton
            content="Create"
            icon="cis-plus"
            @click="create"
            :options="{ disabled: creating }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="7">
            <CRow class="row-gap-3">
              <CCol md="6">
                <div
                  style="position: relative"
                  @click="showModalSelectOrder = true"
                >
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  />
                  <TInputText
                    label="Purchase order"
                    :value="temp.purchase && temp.purchase.id"
                    placeholder="Select order"
                  />
                </div>
              </CCol>

              <CCol md="6">
                <TInputText
                  label="Supplier"
                  :value="temp.purchase && temp.purchase.supplier.name"
                  disabled
                />
              </CCol>
            </CRow>

            <CRow class="row-gap-3 mt-3">
              <CCol md="3">
                <TInputDateTime
                  label="Voucher date"
                  :value.sync="input.voucher_date"
                />
              </CCol>

              <CCol md="3">
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>

              <CCol md="6">
                <TInputMoney
                  label="Amount"
                  :value.sync="input.amount"
                  :currency="input.currency_id"
                />
              </CCol>

              <CCol md="12">
                <TInputTextarea
                  label="Description"
                  placeholder="Description"
                  :value.sync="input.description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="5">
            <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <SModalSelectPurchaseOrder
      :show.sync="showModalSelectOrder"
      @select="onChangeOrder"
      money_column_type="compensation"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["compensation", "compensation_refund"].includes(value);
      },
    },
  },
  data() {
    return {
      temp: {},
      input: {},
      receipts: [],
      showModalSelectOrder: false,
    };
  },
  computed: {
    store() {
      return this.type == "compensation"
        ? "accounting.transactions_compensation_purchase"
        : "accounting.transactions_compensation_purchase_refund";
    },
    creating() {
      return this.$store.getters[this.store + ".creating"];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(this.store + ".create", this.getDataCreate())
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "supplier",
        "receipts[0][receiptable_id]": this.temp.purchase?.id,
        "receipts[0][receiptable_type]": "purchase",
      };

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index + 1}][receiptable_type]`] = "file";
        params[`receipts[${index + 1}][file]`] = receipt.file;
      });

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    onChangeOrder(purchase) {
      console.log(purchase)
      this.$set(this.temp, "purchase", purchase);
      this.$set(this.input, "transactionable_id", purchase.supplier_id);
      this.$set(this.input, "currency_id", purchase.currency_id);
      if (this.type == "compensation_refund" && purchase.compensation > 0)
        this.$set(this.input, "amount", purchase.compensation);
    },
  },
};
</script>
