<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #compensation>
        <Compensation type="compensation" />
      </template>
      <template #refund>
        <Compensation type="compensation_refund" />
      </template>
    </TTabs>
  </div>
</template>

<script>
import Compensation from "./Compensation.vue";
export default {
  components: {
    Compensation,
  },
  data() {
    return {
      tabs: [
        {
          key: "compensation",
          name: "Compensation",
          icon: "cisLibraryBooks",
        },
        {
          key: "refund",
          name: "Refund",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
